import http from "../util/http/studentHttp";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
export default {
    /**
     * 查询会议列表列表
     */
    getMeetingPage: function(params) {
        return http.get(baseUrl + "/safeMeeting/page", params);
    },
    /**
     * 查询会议主持人
     */
    getMeetingLeaderList: function(params) {
        return http.get(baseUrl + "/plan/offline/emcee/list", params);
    },

    /**
     *  验证会议计划
     */
    getValidateMeetingPlan(params) {
        return http.get(baseUrl + "/offline/train/plan/valid", params);
    },

    /**
     * 查询会议详情
     */
    getMeetingDetail: function(params) {
        return http.get(baseUrl + "/offline/train/planid", params);
    },
    /**
     * 新增会议记录
     */
    postOfflineRecord: function(params) {
        return http.post(baseUrl + "/plan/offline/record/add", params);
    },
};
