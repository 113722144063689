/**
 *  会议状态
 */
export const getMeetingStates = () => {
    return [
        {
            key: null,
            value: "所有",
        },
        {
            key: "14",
            value: "未开始",
        },
        {
            key: "20",
            value: "未开始",
        },
        {
            key: "21",
            value: "进行中",
        },
        {
            key: "22",
            value: "延期中",
        },
        {
            key: "25",
            value: "已完成",
        },
        {
            key: "30",
            value: "过期未完成",
        },
    ];
};

/**
 * 格式化会议状态
 */
export const formatterMeetingState = (state) => {
    const stateList = getMeetingStates();
    const findItem = stateList.find((item) => item.key == state);
    return findItem && findItem.value ? findItem.value : "";
};

/**
 *  格式化会议类别
 */
export const filterManySubjectNameStr = (item) => {
    if (!item || !item.subjectList || item.subjectList.length == 0) {
        return "无";
    }
    if (item instanceof String) {
        return item;
    }
    const temp = [];
    for (let i = 0; i < item.subjectList.length; i++) {
        const object = item.subjectList[i];
        !!object.subjectName && temp.push(object.subjectName);
    }
    const subjectStr = temp.join(",");
    return subjectStr;
};

/**
 * 格式化会议主持人
 */
export const filterPlanLeaderList = (planLeaderList) => {
    if (!planLeaderList || planLeaderList.length == 0) return "无";

    const findItem = planLeaderList.find((item) => {
        return item.typeCode === "system_org_indentity_emcee";
    });
    return findItem && findItem.realName ? findItem.realName : "无";
};
