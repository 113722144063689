var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "meeting-prepare-wrapper" },
    [
      _c("div", { staticClass: "left-bd" }, [
        _vm.planData && _vm.planData.planBaseInfoDto
          ? _c("div", { staticClass: "base-info-card" }, [
              _c("div", { staticClass: "c-hd-group" }, [
                _c("div", { staticClass: "hd-group-left" }, [
                  _c("div", { staticClass: "hd-title" }, [
                    _vm._v(_vm._s(_vm.planData.planBaseInfoDto.planName))
                  ]),
                  _c("div", { staticClass: "hd-tag hd-state-tag" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatterMeetingState")(
                          _vm.planData.planBaseInfoDto.state
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "hd-tag hd-type-tag" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("filterPlanType")(
                          _vm.planData.planBaseInfoDto.addType
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "hd-tag hd-subject-tag" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("filterManySubjectNameStr")(
                          _vm.planData.planBaseInfoDto.extJsonObj
                        )
                      )
                    )
                  ])
                ]),
                _vm.planData.planBaseInfoDto &&
                _vm.planData.planBaseInfoDto.addType == 12 &&
                _vm.planData.planBaseInfoDto.validityTime &&
                _vm.planData.planBaseInfoDto.validityTime.length > 0
                  ? _c("div", { staticClass: "hd-group-right" }, [
                      _c("span", [_vm._v("补录延期时间：")]),
                      _c("span", [_vm._v(_vm._s(_vm.planData.validityTime))])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "c-body-group" }, [
                _c("div", { staticClass: "bd-left" }, [
                  _c(
                    "div",
                    { staticClass: "bd-ft-process" },
                    [
                      _c("el-progress", {
                        attrs: {
                          type: "circle",
                          percentage: _vm.formatterPercentage(
                            _vm.planData.planBaseInfoDto.finishRate
                          ),
                          color: "#409EFF",
                          width: 90,
                          "show-text": false
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bd-ft-process-count finish-count" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.planData.planBaseInfoDto.finishCount || 0
                            ) + "人"
                          )
                        ]
                      ),
                      _c("div", { staticClass: "bd-ft-process-state" }, [
                        _vm._v("已完成")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bd-ft-process",
                      staticStyle: { "margin-left": "30px" }
                    },
                    [
                      _c("el-progress", {
                        attrs: {
                          type: "circle",
                          percentage: _vm.formatterPercentage(
                            _vm.planData.planBaseInfoDto.unFinishRate
                          ),
                          color: "#FF5959",
                          width: 90,
                          "show-text": false
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bd-ft-process-count unfinished-count" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.planData.planBaseInfoDto.unFinishCount || 0
                            ) + "人"
                          )
                        ]
                      ),
                      _c("div", { staticClass: "bd-ft-process-state" }, [
                        _vm._v("未完成")
                      ])
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "bd-mid" }, [
                  _c("div", { staticClass: "row-cell" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "row-cell-desc" }, [
                      _vm._v(
                        _vm._s(_vm.planData.planBaseInfoDto.orgName || "无")
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row-cell" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "row-cell-desc" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("timeFormatFilter")(
                              _vm.planData.planBaseInfoDto.startTime
                            )
                          ) +
                          "至" +
                          _vm._s(
                            _vm._f("timeFormatFilter")(
                              _vm.planData.planBaseInfoDto.endTime
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row-cell" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "row-cell-desc" }, [
                      _vm._v(
                        _vm._s(_vm.planData.planBaseInfoDto.userCount || 0) +
                          "人"
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "bd-right" }, [
                  _c("div", { staticClass: "row-cell" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "row-cell-desc" },
                      [
                        _c("span", { staticClass: "cell-desc-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterPlanLeaderList")(
                                _vm.planData.planLeaderList
                              )
                            )
                          )
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "cell-switch-button",
                            attrs: { type: "text", icon: "el-icon-sort" },
                            on: {
                              click: function($event) {
                                return _vm.onClickChangeLeader(
                                  _vm.planData.planLeaderList[0]
                                )
                              }
                            }
                          },
                          [_vm._v("更换人员")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row-cell" }, [
                    _vm._m(4),
                    _c(
                      "div",
                      { staticClass: "row-cell-desc" },
                      [
                        _c("span", { staticClass: "cell-desc-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.planData.classRoomDto.classroomName || "无"
                            )
                          )
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "cell-switch-button",
                            attrs: { type: "text", icon: "el-icon-sort" },
                            on: { click: _vm.onClickChangeRoom }
                          },
                          [_vm._v("更换地点")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "left-meeting-group" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "会议简介", name: "info" } },
                  [
                    _vm.planData && _vm.planData.planBaseInfoDto
                      ? _c("div", { staticClass: "c-meeting-info" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.planData.planBaseInfoDto.introduction ||
                                  "暂无简介"
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "会议内容", name: "content" } },
                  [
                    _vm.planData.videoList && _vm.planData.videoList.length > 0
                      ? _c("div", { staticClass: "m-video-box" }, [
                          _c("div", { staticClass: "m-box-hd" }, [
                            _vm._v("会议视频")
                          ]),
                          _c(
                            "div",
                            { staticClass: "m-video-group" },
                            _vm._l(_vm.planData.videoList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "c-card-video-item animation-card",
                                  class: item.checked
                                    ? "card-item-checked"
                                    : "card-item-normal"
                                },
                                [
                                  _c("div", {
                                    staticClass: "video-item-mask",
                                    style: {
                                      display: item.checked ? "block" : "none"
                                    }
                                  }),
                                  _c("video", {
                                    staticClass: "video-item-img",
                                    attrs: { src: item.fileUrl }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "video-icon",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onClickVideoItem(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../../assets/img/meeting/player_play.png")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "video-item-name" },
                                    [_vm._v(_vm._s(item.fileName))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "check-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickChecked(item)
                                        }
                                      }
                                    },
                                    [
                                      item.checked
                                        ? _c("i", {
                                            staticClass: "el-icon-success"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm.planData.docList && _vm.planData.docList.length > 0
                      ? _c("div", { staticClass: "m-doc-box" }, [
                          _c("div", { staticClass: "m-box-hd" }, [
                            _vm._v("会议文档")
                          ]),
                          _c(
                            "div",
                            { staticClass: "m-doc-group" },
                            _vm._l(_vm.planData.docList, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "c-card-doc-item animation-card",
                                  class: item.checked ? "card-item-checked" : ""
                                },
                                [
                                  _c("div", { staticClass: "doc-type" }, [
                                    _c("img", {
                                      attrs: { src: _vm.docTypeIcon(item) }
                                    })
                                  ]),
                                  _c("div", { staticClass: "doc-item-name" }, [
                                    _vm._v(_vm._s(item.fileName))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "doc-check-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickChecked(item)
                                        }
                                      }
                                    },
                                    [
                                      item.checked
                                        ? _c("i", {
                                            staticClass: "el-icon-success"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm.planData.picList && _vm.planData.picList.length > 0
                      ? _c("div", { staticClass: "m-video-box m-photo-box" }, [
                          _c("div", { staticClass: "m-box-hd" }, [
                            _vm._v("图片")
                          ]),
                          _c(
                            "div",
                            { staticClass: "m-video-group m-photo-group" },
                            _vm._l(_vm.planData.picList, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "c-card-video-item animation-card",
                                  class: item.checked ? "card-item-checked" : ""
                                },
                                [
                                  _c("div", {
                                    staticClass: "video-item-mask",
                                    style: {
                                      display: item.checked ? "block" : "none"
                                    }
                                  }),
                                  _c("el-image", {
                                    staticClass: "video-item-img",
                                    attrs: {
                                      src: item.fileUrl,
                                      "preview-src-list": [item.fileUrl]
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "video-item-name" },
                                    [_vm._v(_vm._s(item.fileName))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "check-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickChecked(item)
                                        }
                                      }
                                    },
                                    [
                                      item.checked
                                        ? _c("i", {
                                            staticClass: "el-icon-success"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "right-bd" },
        [
          _vm.isShowStartTrainButton
            ? _c(
                "el-button",
                {
                  staticClass: "start-meeting-button",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.planDataExtra.startLoading
                  },
                  on: { click: _vm.onClickStartTrain }
                },
                [
                  _vm._v("开 始 会 议 "),
                  _c("i", { staticClass: "el-icon-thumb el-icon--right" })
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "history-list-group" }, [
            _vm._m(5),
            _vm.planData &&
            _vm.planData.offlineRecordDtoList &&
            _vm.planData.offlineRecordDtoList.length > 0
              ? _c(
                  "div",
                  { staticClass: "history-list-main" },
                  _vm._l(_vm.planData.offlineRecordDtoList, function(
                    item,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index, staticClass: "m-history-card" },
                      [
                        _c("div", { staticClass: "m-card-hd" }, [
                          _c("div", { staticClass: "c-hd-title" }, [
                            _vm._v(
                              "第" + _vm._s(item.trainingNumber) + "次会议"
                            )
                          ]),
                          item.state == 1
                            ? _c(
                                "div",
                                { staticClass: "c-hd-state state-doing" },
                                [_vm._v("进行中")]
                              )
                            : _vm._e(),
                          item.state == 2
                            ? _c(
                                "div",
                                { staticClass: "c-hd-state state-finish" },
                                [_vm._v("结束")]
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "m-card-bd" }, [
                          _c("div", { staticClass: "m-bd-cell" }, [
                            _c("span", [_vm._v("开始时间：")]),
                            _c("span", [_vm._v(_vm._s(item.startTime || "无"))])
                          ]),
                          _c("div", { staticClass: "m-bd-cell" }, [
                            _c("span", [_vm._v("结束时间：")]),
                            _c("span", [_vm._v(_vm._s(item.endTime || "无"))])
                          ]),
                          _c("div", { staticClass: "m-bd-cell" }, [
                            _c("span", [_vm._v("签到人员：")]),
                            _c("span", [
                              _vm._v(
                                "已签到" +
                                  _vm._s(item.userSignInCount || 0) +
                                  "人"
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "m-bd-cell" }, [
                            _c("span", [_vm._v("会议主持人：")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterPlanLeaderList")(
                                    item.planLeaderDtoList
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "m-bd-cell" }, [
                            _c("span", [_vm._v("会议地点：")]),
                            _c("span", [
                              _vm._v(_vm._s(item.classroomName || "无"))
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "m-card-ft" },
                          [
                            item.state == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      size: "small",
                                      loading: _vm.planDataExtra.continueLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickContinueTrain(item)
                                      }
                                    }
                                  },
                                  [_vm._v("继续会议")]
                                )
                              : _vm._e(),
                            item.state == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      size: "small",
                                      loading: _vm.planDataExtra.endLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickOfflineRecord(item)
                                      }
                                    }
                                  },
                                  [_vm._v("结束会议 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickTrainRecordEvent(item)
                                  }
                                }
                              },
                              [_vm._v("会议记录")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "history-list-empty" }, [
                  _vm._v(" 暂无会议历史记录 ")
                ])
          ])
        ],
        1
      ),
      _c("OverTrainingDialog", {
        attrs: {
          propDialogVisible:
            _vm.overTrainingDialogObject.overTrainingDialogVisible,
          propObject: _vm.overTrainingDialogObject,
          endOfflineRecordLoadding: _vm.planDataExtra.endLoading
        },
        on: {
          "update:propDialogVisible": function($event) {
            return _vm.$set(
              _vm.overTrainingDialogObject,
              "overTrainingDialogVisible",
              $event
            )
          },
          "update:prop-dialog-visible": function($event) {
            return _vm.$set(
              _vm.overTrainingDialogObject,
              "overTrainingDialogVisible",
              $event
            )
          },
          "update:endOfflineRecordLoadding": function($event) {
            return _vm.$set(_vm.planDataExtra, "endLoading", $event)
          },
          "update:end-offline-record-loadding": function($event) {
            return _vm.$set(_vm.planDataExtra, "endLoading", $event)
          },
          addOfflineRecordData: _vm.onPwdAfterStartTrain,
          endCurrentRecord: _vm.endCurrentRecord
        }
      }),
      _c("SelectLeaderDialog", {
        attrs: {
          visible: _vm.dialogLeaderObject.visible,
          planId: _vm.planData.planId,
          currentLeader: _vm.dialogLeaderObject.currentLeader
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.dialogLeaderObject, "visible", $event)
          },
          "get-data": _vm.monitorSelectLeaderObject
        }
      }),
      _c("SelectRoomDialog", {
        ref: "selectRoom",
        attrs: {
          visible: _vm.dialogClassroomObject.visible,
          currentRoom: _vm.dialogClassroomObject.currentRoom,
          planId: _vm.planData.planId,
          typeCode: _vm.dialogClassroomObject.typeCode
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.dialogClassroomObject, "visible", $event)
          },
          "get-data": _vm.onClickListClassroomSelected
        }
      }),
      _c("VideoPlayDialog", {
        attrs: {
          visible: _vm.dialogVideoObject.visible,
          videoUrl: _vm.dialogVideoObject.videoUrl
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.dialogVideoObject, "visible", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-cell-title" }, [
      _c("span", [_vm._v("所属组织")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-cell-title" }, [
      _c("span", [_vm._v("会议周期")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-cell-title" }, [
      _c("span", [_vm._v(" 参会人员")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-cell-title" }, [
      _c("span", [_vm._v("会议主持人")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-cell-title" }, [
      _c("span", [_vm._v("会议地点")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "hd-group" }, [
      _c("span", [_vm._v("会议历史记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }