export default {
    name: "",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        videoUrl: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        /**
         * 关闭按钮
         */
        onCloseEvent() {
            this.$emit("update:visible");
            this.$refs.videoPlayer && this.$refs.videoPlayer.pause();
        },
    },
};
