var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      staticClass: "video-play-dialog",
      attrs: {
        title: "视频播放",
        visible: _vm.visible,
        "before-close": _vm.onCloseEvent,
        width: "60%"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("video", {
        ref: "videoPlayer",
        staticClass: "video-play-wrap",
        attrs: {
          id: "videoId",
          disablepictureinpicture: "",
          disableRemotePlayback: "",
          controls: "",
          autoplay: "",
          playsinline: "",
          controlslist: "nofullscreen nodownload",
          muted: "",
          src: _vm.videoUrl
        },
        domProps: { muted: true }
      }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              staticClass: "sure-btn",
              attrs: { type: "primary" },
              on: { click: _vm.onCloseEvent }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }