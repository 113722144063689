import meetingApi from "../../../api/meetingApi";
import StoreTrainDBManger from "../../../db/StoreTrainDBManger";
import { HudGlobal } from "../../../util/HudGlobal";
import StoreDBManger from "../../../util/StoreDBManger";
import studyPrepare from "../../mixins/studyPrepare";
import { filterPlanLeaderList, formatterMeetingState, filterManySubjectNameStr } from "../utils/MeetingFormatter";
import VideoPlayDialog from "../../../components/video-play-dialog/VideoPlayDialog.vue";

export default {
    name: "MeetingPrepare",
    mixins: [studyPrepare],
    components: {
        VideoPlayDialog,
    },
    data() {
        return {
            routeParam: {
                type: "",
                planId: "",
            },
            activeTab: "info", // 当前选择
            dialogVideoObject: {
                visible: false,
                videoUrl: "", // 视频的url
            },
        };
    },
    mounted() {
        const query = this.$route.query;
        Object.assign(this.routeParam, query);

        const that = this;
        // 获取计划信息
        StoreTrainDBManger.getCurrentPlanItemFromDB((item) => {
            item && (that.planData.planBaseInfoDto = item);
        });
        this.getPlanOne();
    },
    methods: {
        /**
         * 点击选中
         */
        onClickChecked(item) {
            item.checked = !item.checked;
        },

        /**
         * 点击视频的item
         */
        onClickVideoItem(item) {
            this.dialogVideoObject.videoUrl = item.fileUrl;
            this.dialogVideoObject.visible = true;
        },

        /**
         * 文件类型图片
         */
        docTypeIcon(item) {
            if (item.fileUrl && item.fileUrl.length > 0) {
                const fileExtension = item.fileUrl
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (fileExtension === "pdf") return require("../../../assets/img/meeting/pdf_icon.png");
                else {
                    require("../../../assets/img/meeting/word_icon.png");
                }
            }
        },
        /**
         * 格式化环形
         */
        formatterPercentage(val) {
            if (!val) return 0;
            return parseFloat(val);
        },

        formatterEmptyStr() {},
    },
    filters: {
        filterPlanLeaderList,
        formatterMeetingState,
        filterManySubjectNameStr,
    },
};
